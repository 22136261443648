import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RoutingProvider } from 'library/routing';
import { setAllQueryDefaults } from 'api/apiConfig';
import { getRoutingList } from 'api/routing';
import { PDFExportContextProvider } from 'components/fragments/PDFExport/context/PDFExportContext';
import { NotificationContextProvider } from 'components/shared/Notifications/store/NotificationContext';
import { HideWhsPriceSettingsProvider } from 'components/shared/hooks/useHideWhsPriceSettings';
import { ZoomCorrectionProvider } from 'components/shared/hooks/useZoomCorrectionSettings';
import { ResourceLocation, RouteResponse } from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { isScannerApp } from 'helpers/app';
import { useAppStore } from 'store/appStore';
import Router from './Router';
import './builder-settings';
import { ViewportSizeProvider } from './helpers';

interface WindowProps extends Window {
	initialData: {
		routing: ResourceLocation | null;
		page: {
			props: {
				page: React.ReactElement;
			};
		};
		locale: string;
		isProduction?: boolean;
	};
	ssrError: { msg: string };
}

declare const window: WindowProps;
const initialData = window.initialData || { routing: null, page: null, locale: 'en' };
const ssrError = window.ssrError || { msg: null };
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 5 * 1000, // global default to prevent duplicate queries across render cycles
		},
	},
});

if (isScannerApp) {
	broadcastQueryClient({
		queryClient: queryClient,
		broadcastChannel: 'bestsellerApp',
	});
}

setAllQueryDefaults(queryClient);
let routing: HttpResponse<RouteResponse, unknown>;

function getRouting(): Promise<void | Record<string, string>> {
	const locationPath = window.location.pathname + window.location.search;

	const query = { path: locationPath };

	return getRoutingList(query).then((response) => {
		routing = response;

		if (window.location.pathname !== routing.data.route.externalRoute)
			window.history.replaceState({}, '', routing.data.route.externalRoute);
	});
}

function renderPage(): void {
	const container = document.getElementById('react-app');
	if (!container) {
		throw new Error('react-app-element not found!');
	}

	function Root(): JSX.Element {
		const updateSegmentationId = useAppStore((state) => state.setSegmentationId);
		updateSegmentationId(routing.data.segmentationId);

		return (
			<RoutingProvider>
				<Routes>
					<Route
						path="*"
						element={
							<Router
								page={initialData.page?.props?.page as React.ReactElement}
								routingData={initialData.routing || routing?.data.route}
								isNotFound={routing?.data.isNotFound ?? false}
								{...(routing?.data.userMessage && { userMessage: routing?.data.userMessage })}
								routingError={routing?.error}
								segmentationId={routing.data.segmentationId ?? 1}
							/>
						}
					/>
				</Routes>
			</RoutingProvider>
		);
	}
	const router = createBrowserRouter([{ path: '*', Component: Root }]);

	const app = (
		<QueryClientProvider client={queryClient}>
			<HelmetProvider>
				<NotificationContextProvider>
					<ZoomCorrectionProvider>
						<HideWhsPriceSettingsProvider>
							<ViewportSizeProvider>
								<PDFExportContextProvider>
									<RouterProvider router={router} />
								</PDFExportContextProvider>
							</ViewportSizeProvider>
						</HideWhsPriceSettingsProvider>
					</ZoomCorrectionProvider>
				</NotificationContextProvider>
			</HelmetProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
	const root = createRoot(container);
	root.render(app);
}

if (ssrError.msg) {
	console.error('SSR Failed', ssrError.msg);
}

getRouting().then(() => {
	renderPage();
});
