import React, { FC, useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useBasketCreateMutation, useBasketQuery } from 'api/basket';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import AccountSelectorBody from 'components/fragments/AccountSelectorBody';
import { AlertTypes } from 'components/shared';
import { Button } from 'components/shared/Button';
import { DrawerBody, DrawerFooter } from 'components/shared/Drawer';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import {
	FeatureFlag,
	PageType,
	ShipToInfoResponse,
	UpdateNameAndShipTosForBasketRequest,
} from 'generated/data-contracts';
import { AppWebViews, isScannerApp, messageToApp } from 'helpers/app';
import { useViewportHeight } from 'helpers/useViewportSizeHeight';
import styles from './EditView.module.scss';

interface EditViewProps {
	handleClose: () => void;
	noPreselectedAccounts?: boolean;
}

const EditView: FC<EditViewProps> = ({ handleClose, noPreselectedAccounts }) => {
	const { notificationActions } = useNotificationContext();

	const { data: translations } = useTranslationQuery();
	const { data: basket } = useBasketQuery();
	const { data: layout } = useLayoutQuery();

	const createBasket = useBasketCreateMutation();

	const { isSmallScreenSize } = useViewportHeight();

	const queryClient = useQueryClient();

	const [isLoading, setIsLoading] = useState(false);

	const [selectedShipTos, setSelectedShipTos] = useState<ShipToInfoResponse[]>([]);

	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

	const [filterList, setFilterList] = React.useState<ShipToInfoResponse[]>([]);

	const hasEditAccountsFeatureFlag = layout?.userFeatureFlags.includes(FeatureFlag.EditAppAccounts);

	const onToggleClick = (): void => {
		setIsExpanded(!isExpanded);
	};

	const handleChange = useCallback(
		(event): void => {
			const { value } = event.target;

			setSelectedShipTos((prevState) => {
				if (prevState.some((shipTo) => shipTo.id === value)) {
					// handle if selectedShipTos gets empty
					if (prevState.length === 1) {
						setIsExpanded(false);
					}

					// remove shipTo from selected list
					return prevState.filter((shipTo) => shipTo.id !== value);
				}

				// find checked shipTo object from showed list
				const checkedShipTo = filterList.find((shipTo) => shipTo.id === value);
				// add shipTo to selected list
				if (checkedShipTo) return [...prevState, checkedShipTo];

				return prevState;
			});
		},
		[filterList],
	);

	const handleSubmit = (event): void => {
		event.preventDefault();

		if (basket === undefined) return;
		setIsLoading(true);

		const shipToIds = selectedShipTos.map(({ id }) => id);

		const query: UpdateNameAndShipTosForBasketRequest = {
			shipToIds,
		};

		const basketId = basket.id;
		createBasket.mutate(
			{ basketId, query },
			{
				onSuccess: (response) => {
					const { data } = response;
					const redirectRoute = data.redirectRoute;

					if (redirectRoute) {
						// redirect to brandselector if shiptos not have access to current brand
						// redirect to frontpage if shiptos not have access to basket but have to brand
						// Navigate with full page reload to re-initialize all the data in the store

						const isRedirectToOnboardFlow =
							redirectRoute.route.pageType === PageType.BrandSelector ||
							redirectRoute.route.pageType === PageType.AccountSelector;

						if (hasEditAccountsFeatureFlag && isScannerApp && isRedirectToOnboardFlow) {
							messageToApp({ type: 'openBrandSelector' });
						} else {
							window.location.href = window.location.origin + redirectRoute.route.externalRoute;
						}
						return;
					}

					if (noPreselectedAccounts) {
						messageToApp({ type: 'redirectToTabAndOpenUrl', tabId: AppWebViews.SHOP, url: '/' });
					}
					queryClient.invalidateQueries();
					handleClose();
				},
				onSettled: () => {
					messageToApp({ type: 'accountsChanged' });
				},
				onError: (error) => {
					notificationActions.addNotification({ children: error.data.detail, type: AlertTypes.DANGER });
				},
			},
		);
	};

	return (
		<>
			<DrawerBody className={classNames(styles.body, { [styles.hasNoTopPadding]: isExpanded })}>
				<AccountSelectorBody
					handleChange={handleChange}
					onToggleClick={onToggleClick}
					isSelectedShipTosExpanded={isExpanded}
					setFilterList={setFilterList}
					selectedShipTos={selectedShipTos}
					setSelectedShipTos={setSelectedShipTos}
					handleSubmit={handleSubmit}
					isCreateBasketView={noPreselectedAccounts}
					formId="editBasketForm"
				/>
			</DrawerBody>

			<DrawerFooter className={styles.footer}>
				<Button
					form={'editBasketForm'}
					type="submit"
					variant="dark"
					isFullWidth
					isLoading={isLoading}
					disabled={selectedShipTos.length === 0}
				>
					{noPreselectedAccounts
						? translations?.openBaskets.create.submit
						: translations?.openBaskets.edit.submit}
				</Button>

				<Button
					className={classNames({ ['u-mt-sm']: !isSmallScreenSize })}
					variant="secondary"
					isFullWidth
					onClick={handleClose}
				>
					{translations?.shared.cancel}
				</Button>
			</DrawerFooter>
		</>
	);
};

export default EditView;
